@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&family=Roboto:ital,wght@0,300;0,400;0,700;1,400;1,700&display=swap');

* {
  margin: 0px;
  padding: 0px;
}

/* variables */
:root {
  --color-primary: #D1B270;
  --color-primary-light: #FFDA8A;
  --color-primary-dark: #806D45;
  --color-grey-dark: #868686;
  --color-grey-light: #f2f2f2;

  --color-white: #f9f9f9;
  --color-black: #1F1F1F;

  --color-overlay: rgba(31, 31, 31, .5);

  --transition: .3s;

  --box-shadow: 0px 3px 6px 0px rgba(31,31,31,0.2);
  --text-shadow: 0px 2px 5px var(--color-black);
}

body {
  font-family: 'Roboto';
}

#root {
  max-width: 100vw;
  overflow-x: hidden;
}

/* Elements */
ul {
  list-style: none;
  margin: 0px;;
}

li {
  margin: 0px;
}

h1, h2, h3 {
  font-family: 'Montserrat';
  color: var(--color-primary);
}

h1 {
  font-weight: 700;
  margin-bottom: 2rem;
  font-size: 40px;
}

h2 {
  font-weight: 700;
  margin-bottom: 2rem;
  font-size: 32px;
}

h2 {
  font-weight: 700;
  margin-bottom: 2rem;
  font-size: 32px;
}


p {
  font-size: 16px;
  margin-bottom: 1rem;
}

/* Layout */
.page {
  width: 100vw;
  max-width: 100vw;
  min-height: calc(100vh - 5rem);
  background-color: var(--color-white);
}